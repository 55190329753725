<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      :title="product.title || product.name"
      lazy
      static
    >
      <b-row class="my-1">
        <b-col v-if="product.variations && product.variations.length" :md="2">
          <span><strong>Variation</strong></span>
        </b-col>
        <b-col :md="1">
          <span><strong>Stock</strong></span>
        </b-col>
        <b-col :md="1">
          <span><strong>SKU</strong></span>
        </b-col>
        <b-col :md="3">
          <span><strong>Livraison</strong></span>
        </b-col>
        <b-col :md="3">
          <span><strong>Specificité</strong></span>
        </b-col>
        <b-col :md="2">
          <span><strong>Retour en stock</strong></span>
        </b-col>
      </b-row>
      <template v-if="product.variations && product.variations.length">
        <b-row v-for="(variation, keyV) of product.variations" :key="keyV" class="my-1 d-flex align-items-center">
          <b-col :md="2">
            <span v-for="(attribute, keyA) of variation.attributes" :key="keyA">
              <strong><u>{{ `${attribute.name.charAt(0).toUpperCase()}${attribute.name.slice(1)}:` }}</u></strong> {{ `${attribute.option}` }}
            </span>
            <br>
            <span><strong>{{ variation.sku }}</strong></span>
          </b-col>
          <b-col :md="1">
            <v-select
              v-model="variation.stock_status"
              placeholder="Pays"
              label="Pays"
              :options="optionStock"
              :clearable="false"
              class="invoice-filter-select mt-1"
            />
          </b-col>
          <b-col :md="1">
            <b-form-input v-model="variation.sku" />
          </b-col>
          <b-col :md="3">
            <b-form-input v-model="variation.description" />
          </b-col>
          <b-col :md="3">
            <b-form-input v-model="variation.specificity" />
          </b-col>
          <b-col :md="2">
            <flat-pickr
              v-model="variation.returnStock"
              placeholder="Date de retour en stock"
              class="form-control mr-1"
            />
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            :disabled="loading"
            @click="updateValue()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Enregistrer</span>
          </b-button>
        </b-row>
      </template>
      <!--<template v-else-if="product.shop">
        <b-row class="my-1">
          <b-col :md="2">
            <b-form-input v-model="product.shop.find(s => s.key === 'TEMPLE').value" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            @click="updateValue(true)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Enregistrer le stock</span>
          </b-button>
        </b-row>
      </template>!-->
    </b-modal>
    <b-card>
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select mt-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-row>
      <b-col
        cols="12"
      >
        <div class="mt-1 mb-3">
          <b-row>
            <b-col
              cols="12"
            >
              <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50 text-primary"
                  size="20"
                />
                <b-input-group class="input-group-merge shadow-none">
                  <b-form-input
                    placeholder="Rechercher un produit..."
                    style="height: auto;"
                    class="border-0 shadow-none"
                    @input="filter"
                  />
                </b-input-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row> -->
    <b-card title="Vous pouvez modifier les produits directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                placeholder="Rechercher un produit..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-col
          v-for="(pdct, keyP) in products"
          :key="keyP"
          md="3"
          lg="3"
          class=""
        >
          <b-card
            class="ecommerce-card"
            no-body
          >
            <div
              v-if="pdct.images && pdct.images.length"
              class="item-img text-center"
            >
              <b-img
                :alt="`${pdct.title}`"
                fluid
                class="card-img-top"
                style="height: 180px; object-fit: cover"
                :src="pdct.images[0].src"
              />
            </div>

            <!-- pdct Details -->
            <b-card-body>
              <div class="item-wrapper d-flex justify-content-between">
                <div>
                  <h5 class="item-price text-primary text-lighten-2">
                    {{ pdct.price }} €
                  </h5>
                </div>
                <div v-if="pdct.variations">
                  <h5 v-if="pdct.variations.filter(v => v.stock_status === 'outofstock').length > 0" class="item-price text-primary text-error">
                    {{ `${pdct.variations.filter(v => v.stock_status === 'outofstock').length} tailles indisp.` }}
                  </h5>
                  <h5 v-else class="item-price text-primary text-success">
                    {{ 'Tous en stock' }}
                  </h5>
                </div>
              </div>
              <h4 class="item-name">
                {{ pdct.title || pdct.name }}
              </h4>
              <b-card-text v-if="pdct.isOnBundle" class="item-description flex">
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="text-primary"
                  size="15"
                />
                <span class="ml-1 font-size-sm text-primary">Attention ! Bundle</span>
              </b-card-text>
            </b-card-body>

            <!-- Product Actions -->
            <div class="item-options text-center">
              <b-button
                variant="flat-primary"
                tag="a"
                class="btn-cart mb-1"
                @click="openModal(pdct)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Modifier le produit</span>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BButton,
  BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BFormInput,
    vSelect,
    BCard,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardBody,
  },
  data() {
    return {
      baseProducts: [],
      products: [],
      product: {},
      showModal: false,
      options: { mode: 'code' },
      categoryInformations: [],
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'PT', 'EN', 'NL', 'DK', 'UK'],
      optionStock: ['instock', 'outofstock'],
      loading: false,
    }
  },
  watch: {
    country: {
      deep: true,
      handler: 'getProducts',
    },
  },
  async mounted() {
    try {
      await this.getProducts()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getProducts() {
      try {
        this.loading = true
        const { products } = await this.$http.get('/admin/list-data-product', { params: { filter: { country: this.country } } })
        this.products = products
        this.baseProducts = products
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async openModal(p) {
      const { product } = await this.$http.get(`/admin/products/${p._id}`)
      this.product = product.product
      this.product._id = product._id
      this.showModal = true
      console.log(product.product)
    },
    async updateValue() {
      try {
        this.loading = true
        await this.$http.put(`admin/products/${this.product._id}/stock`, { variations: this.product.variations, country: this.country, product_id: this.product.id })
        this.showModal = false
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.products = this.baseProducts
      } else {
        const value = e
        this.products = this.baseProducts.filter(product => product.variations?.find(p => p?.sku?.toLowerCase() === value.toLowerCase()) || product.title?.toLowerCase().includes(value.toLowerCase()) || product.name?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
